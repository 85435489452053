<template>
  <div class="page setting">
    <HeadBar class="headbar" title="设置"></HeadBar>
    <div class="setting-content">
      <ul>
        <li>
          <div>建议与反馈</div>
          <div class="scan-feedback">
            <p>请扫码后反馈</p>
            <div class="scan-feedback-img">
              <img :src="feedbackQRCode" alt="" />
            </div>
          </div>
        </li>
        <li>
          <div>相关协议</div>
          <div class="agreement">
            <p @click="handleCheckAgreement('agreementUser')">
              《用户服务协议》
            </p>
            <p @click="handleCheckAgreement('agreementPrivacy')">
              《隐私权政策》
            </p>
          </div>
        </li>
        <li>
          <div>切换账号</div>
          <div class="login-out">
            <p @click="handleLoginout">退出当前账号</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import useQRCode from '@/composables/useQRCode'
import IMAGE_URLS from '@/constants/images/index'
import { sendLog } from '@/directives/v-log/log'
import { getCarplayInfo } from '@/service/carplay-info'
import { get } from 'lodash'
import { Dialog, Toast } from 'vant'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'Setting',
  setup() {
    const store = useStore()
    const router = useRouter()

    const macAddress = computed(() => store.state.macAddress)
    const userInfo = computed(() => store.state.userInfo)
    const { getQRCodeURL } = useQRCode()

    let feedbackQRCode = ref(IMAGE_URLS.VIP_MODAL.DEFAULT_QR_CODE)

    const handleCheckAgreement = (name) => {
      router.push({
        name,
      })
    }

    const handleLoginout = () => {
      Dialog.confirm({
        className: 'global-force-login',
        confirmButtonText: '退出账号',
        cancelButtonText: '取消',
        message: '确定退出当前账号？',
      }).then(() => {
        store.dispatch('loginout', {
          mac_id: macAddress.value,
          unionid: userInfo.value.unionid,
        })
        store.dispatch('singTime/resetSingTimeData')
        // 收藏功能下线
        // store.dispatch('collect/resetUserCollectList')
        sendLog({
          event_type: '10000~50000',
          event_name: 10031,
          event_data: {
            str1: '我的',
            str2: '退出登录',
            str3: '点击退出',
            str4: 'click',
          },
        })
        router.push({
          name: 'home',
        })
      })
    }

    const generateFeedbackQRCode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'feedback_qr', '')) {
        const qrData = await getQRCodeURL(`${data.feedback_qr}`)
        if (qrData) {
          feedbackQRCode.value = qrData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    onMounted(() => {
      generateFeedbackQRCode()
    })

    return {
      feedbackQRCode,
      handleLoginout,
      handleCheckAgreement,
    }
  },
}
</script>

<style lang="stylus" scoped>
.setting
  height 100vh
  overflow hidden
  padding-left 375px
  padding-right 375px
  // background: #000000B2
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding-left 112px
    padding-right 112px
  .headbar
    background: none
  .setting-content
    li
      display flex
      justify-content space-between
      align-items center
      color #1D1D1F
      padding 55px 0 57px
      border-bottom 2px solid #1D1D1F1A
      font-size 32px
      .agreement
        display flex
        p:first-child
          margin-right 40px
      p
        border-radius: 85px;
        border: 2px solid #1D1D1F33;
        background: rgba(255, 255, 255, 0.08);
        padding 26px 52px
        color #1D1D1FCC
        font-size var(--font-size-medium)
      .login-out
        p
          color: #BE2D35;
    @media screen and (max-width 1200px) and (min-height 1200px)
      font-size 26px
      p
        padding 20px 41px!important
    .scan-feedback
      display flex
      justify-content space-between
      align-items center
      .scan-feedback-img
        width 210px
        height 210px
        border-radius 8px
        display flex
        justify-content center
        align-items center
        padding 6px
        background #fff

        img
          width 198px
          height 198px
      p
        margin-right 40px
        border none
        font-size var(--font-size-32)
        color rgba(29, 29, 31, .5)
        text-align right
        width 300px
        padding 0 !important
</style>
